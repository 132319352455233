<template>
  <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.99984 0.333313C4.15889 0.333313 2.6665 1.8257 2.6665 3.66665C2.6665 5.5076 4.15889 6.99998 5.99984 6.99998C7.84079 6.99998 9.33317 5.5076 9.33317 3.66665C9.33317 1.8257 7.84079 0.333313 5.99984 0.333313ZM3.99984 3.66665C3.99984 2.56208 4.89527 1.66665 5.99984 1.66665C7.10441 1.66665 7.99984 2.56208 7.99984 3.66665C7.99984 4.77122 7.10441 5.66665 5.99984 5.66665C4.89527 5.66665 3.99984 4.77122 3.99984 3.66665Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.99984 7.66665C3.05432 7.66665 0.666504 10.0545 0.666504 13C0.666504 13.3682 0.964981 13.6666 1.33317 13.6666H10.6665C11.0347 13.6666 11.3332 13.3682 11.3332 13C11.3332 10.0545 8.94536 7.66665 5.99984 7.66665ZM5.99984 8.99998C7.98183 8.99998 9.62715 10.4415 9.94453 12.3333H2.05514C2.37253 10.4415 4.01784 8.99998 5.99984 8.99998Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
import { Vue } from "vue-class-component";

export default class IconProfile extends Vue {}
</script>
